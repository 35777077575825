.footer-bottom {
  background-color: #000; /* ensure the background is dark to contrast with white text */
  color: #fff; /* set text color to white */
  padding: 10px 20px; /* add some padding */
}

.footer-bottom .footer-bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-copyright {
  color: #fff; /* ensure the copyright text is white */
}

.footer-icons {
  display: flex;
  gap: 15px; /* space between the icons */
}

.footer-icon-link {
  color: #fff; /* ensure the icon links are white */
}

.footer-bottom-icon {
  font-size: 24px; /* adjust icon size as needed */
}

/* Additional styling for responsive design, if desired */
@media (max-width: 768px) {
  .footer-bottom .footer-bottom-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-icons {
    margin-top: 10px;
  }
}

.af-view :root {
  --chakra-petch: "Chakra Petch", sans-serif;
  --brand: #ff8700;
  --black: black;
  --casino: Casino, sans-serif;
  --white: white;
  --border-color:#4c2800;
}

.bet-input-wrapper{
  display: flex;
  width: 100%;

}
.bet-input-container {
  display: flex;
  align-items: center;
  /* border: 1px solid #ff9900; */
  border: 1px solid #4c2800;
  border-radius: 10px;
  padding: 10px;
  background-color: #000000;
  color: #ff9900;
  height: 60px; /* Add or adjust height to your preference */
  /* gap: 10px; Space between BetInput and button */
}

.bet-input-icon {
  font-size: 1.2em;
  margin-right: 10px;
  text-shadow: 0 0 15px #ff8700;
}

.bet-input {
  flex-grow: 1;
  background: transparent;
  border: none;
  font-size: 1em;
  color: #ff9900;
  text-shadow: 0 0 15px #ff8700;
  font-family: "Chakra Petch";
}

.bet-input:focus {
  outline: none;
}

.bet-input-max {
  display: flex;
  align-items: center;
  font-family: "Chakra Petch";
}

.max-button {
  background: transparent;
  border: none;
  color: #ff9900;
  cursor: pointer;
  margin-left: 5px;
  font-size: 1em;
  text-shadow: 0 0 15px #ff8700;
}

.max-button:hover {
  color: #ffffff;
}
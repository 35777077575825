/* Ensure the body and html take full height */
html, body {
  height: 100%;
  margin: 0;
}

/* Make the main container take full height */
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header (if you have one) */
.header {
  flex-shrink: 0;
}

/* Main content should grow to take up remaining space */
.content {
  flex: 1;
}

/* Footer should sit at the bottom */
.footer {
  flex-shrink: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 16px 24px;
  position: relative;
}

.navbar .div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 34px;
  position: relative;
}

.navbar .vector {
  height: 32px;
  position: relative;
  width: 154.68px;
}

.navbar .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  margin-top: 5px;
  gap: 34px;
  position: relative;

}

.navbar .text-wrapper {
  color: var(--ff-8700, #FF8700);
  font-family: "Chakra Petch";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  /* margin-top: -1px; */
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.navbar .text-wrapper-2 {
  color: var(--ff-8700, #FF8700);
  font-family: "Chakra Petch";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  opacity: 0.6;
  position: relative;
  width: fit-content;
}

.navbar .div-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  /* padding: 16px 24px; */
  gap: 34px;
  position: relative;
}
.navbar > .div-3 a{
  margin-top: 5px;
}

.navbar .img {
  height: 24px;
  position: relative;
  width: 24px;
}

.navbar .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  margin-top: 6px;
}

.navbar .text-wrapper-3 {
  color: var(--ff-8700, #FF8700);
  font-family: "Chakra Petch";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

html, body {
  background-color: #000; /* Black */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  height: 100%; /* Ensure the html and body fill the viewport height */
}

.frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.frame .frame-div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.frame .frame-group {
  height: 40.97px;
  position: relative;
  width: 40px;
}

.frame .frame-text-wrapper {
  color: #ffffff;
  font-family: "Chakra Petch-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 4px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .frame-element-blue {
  color: #339cfe;
  font-family: "Chakra Petch";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.82px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .frame-element-pink {
  color: #fe33f7;
  font-family: "Chakra Petch";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.82px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .frame-element-green {
  color: #41fe33;
  font-family: "Chakra Petch";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.82px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .frame-element-orange {
  color: #fe9833;
  font-family: "Chakra Petch";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.82px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.connect-wallet-button {
  border-radius: 100px;
  border: 1px solid var(--ff-8700, #FF8700);
}

.connect-wallet-text {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  color: var(--ff-8700, #FF8700);
  font-family: "Chakra Petch";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  background-color: black;
}

.move-button {
  border-radius: 8px;
  background: #AA56FF;
  box-shadow: 0px 0px 24px 0px #9428FF;
  display: center;
  width: 720px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.dark-button {
  border-radius: 8px;
  background:  #b3b0b0;
  box-shadow: 0px 0px 24px 0px #9428FF;
  display: center;
  width: 720px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.move-button-text {
  color: #000;
  text-align: center;
  font-family: "Chakra Petch";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}

.waiting-button {
  border-radius: 8px;
  background:  #000;
  border: 2px solid #AA56FF;
  display: center;
  width: 720px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.waiting-button-text {
  color: #AA56FF;
  text-align: center;
  font-family: "Chakra Petch";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}

.af-view :root {
  --white: white;
  --black: black;
}

.af-view .af-class-body {
  background-color: #000;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.af-view .af-class-shooting-game {
  grid-row-gap: 40px;
  background-color: #07020f;
  border: 2px solid #230552;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1038px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  text-decoration: none;
  display: flex;
  align-items: center; /* Add this line */
  justify-content: center; /* Add this line */
}

.login-button-container {
  position: absolute; /* or fixed, based on your requirements */
  top: 10px; /* adjust as needed */
  right: 10px; /* adjust as needed */
  z-index: 100; /* to ensure it's above other elements */
}

.primary-button {
  width: 100%;
  max-width: 500px;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  background-color: var(--brand);
  border-radius: 8px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  text-decoration: none;
  display: flex;
}

.af-class-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  margin-top: 40px; 
}

.af-class-button-dark {
  background-color: #333; /* Dark background */
  color: #fff; /* Light text */
  /* Other styles for "dark" state */
}

.af-view .af-class-game-header {
  text-align: center;
  color: #9428ff;
  letter-spacing: .04em;
  text-shadow: 0 0 24px rgba(255, 135, 0, .5);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Casino, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.af-view .af-class-game {
  grid-column-gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.af-view .af-class-player-col {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 720px;
  display: flex;
}

.af-view .af-class-play-1 {
  color: #ff31de;
  letter-spacing: .13em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.af-view .af-class-pl {
  color: #93ed00;
  letter-spacing: .13em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.af-view .af-class-main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 720px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.af-view .af-class-grid-overlay {
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 720px;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.af-view .af-class-gamebg {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 720px;
  height: 720px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.af-view .af-class-player-col-2 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 720px;
  display: flex;
}

.af-view .af-class-player {
  color: #339cfe;
  letter-spacing: .13em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.af-view .af-class-player .af-class-pink {
  color: #fe33f7;
}

.af-view .af-class-player .af-class-green {
  color: #41fe33;
}

.af-view .af-class-player .af-class-orange {
  color: #fe9833;
}

.af-view .af-class-text-10 {
  color: #ffb763;
  letter-spacing: .13em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.af-view .af-class-background-video {
  width: 720px;
  height: 720px;
  z-index: 1;
  position: relative;
}

.af-view .af-class-frame-6 {
  grid-column-gap: 8px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.af-view .af-class-grid {
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  z-index: 2;
  position: absolute;
}

.af-view .af-class-objects {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
  z-index: 3;
}

.coming-soon-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  font-family: Casino, sans-serif;
  font-size: 3em;
  color: var(--ff-8700, #FF8700);
  text-align: center;
}

@font-face {
  font-family: 'Casino 3 Dlines';
  src: url('../fonts/Casino3DLines.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Casino';
  src: url('../fonts/Casino.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
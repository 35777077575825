:root {
  --edition-width: 80px;
  --pot-width: 60px;
  --winners-width: 50px;
}

.game-history {
    width: 100%;
    max-width: 1038px;
    margin: 20px auto;
    padding: 20px;
    background-color: rgba(25, 13, 0, 0.5);
    border: 2px solid #4c2800;
    border-radius: 16px;
    color: #ff8700;
  }
  
  .game-history h1 {
    box-shadow: 0 0 16px 4px var(--black);
    color: #ff8700;
    letter-spacing: 0.04em;
    text-shadow: 0 0 24px rgba(255, 135, 0, 0.5);
    margin-top: 0;
    margin-bottom: 0;
    font-family: Casino, sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 100%;
    text-decoration: none;
    text-align: left; /* Changed from center to left */
  }
  
  .history-table {
    width: 100%;
    border-collapse: collapse;
  }

  .history-header,
  .history-item {
    display: grid;
    grid-template-columns: var(--edition-width) var(--pot-width) var(--winners-width) repeat(8, minmax(70px, 1fr)) 50px;
    gap: 5px;
    padding: 10px 0;
    border-bottom: 2px solid #4c2800;
    font-weight: bold;
    align-items: center; /* This will vertically align all items */
  }

  .history-header span,
  .history-item > span {
    text-align: center;
    text-shadow: 0 0 12px #ff8700;
    font-family: Chakra Petch, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
  }

  .image-score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure full height */
  }

  .history-item img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
    text-shadow: 0 0 12px #ff8700;
  }

  .score {
    margin-top: 2px;
    font-size: 10px;
    color: #ff8700;
    text-shadow: 0 0 12px #ff8700;
    font-family: Chakra Petch, sans-serif;
  }

  .result-column {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    height: 100%; /* Ensure full height */
  }

  .history-item.last-item {
    border-bottom: none;
  }
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 5px;
    border: 2px solid #4c2800;
    /* To match the overall style */
}

.dropdown-content .dropdown-item {
    color: var(--ff-8700, #FF8700);
    /* Same color as text-wrapper */
    background-color: black;
    font-family: "Chakra Petch";
    font-size: 16px;
    font-weight: 600;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.dropdown-content .dropdown-item:hover {
    background-color: var(--ff-8700, #FF8700);
    color: var(--ff-ffffff, #FFFFFF);
}

.dropdown:hover .dropdown-content {
    display: block;
}
  
.newsreel-banner {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-top: 4px solid #ff8700; /* Bright orange line above */
    border-bottom: 4px solid #ff8700; /* Bright orange line below */
    background-color: #000; /* Match the background color */

    box-shadow: 0 0 12px 0 var(--black);
    color: #ff8700;
    text-shadow: 0 0 12px #ff8700;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Chakra Petch, sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    gap: 40px;
    width: 100%;
    line-height: 100%;
    text-decoration: none;
    text-align: left; /* Align text to the left */
    padding: 8px 0; /* Add padding to position text between borders */
}

.scroll {
    display: flex;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);

    div {
        font-size: 24px; /* Match the font size from Keynesian Beauty Contest */
        font-family: "Chakra Petch", sans-serif; /* Match the font family */
        text-transform: uppercase;
        white-space: nowrap;
        color: #ff8700; /* Bright orange color */
        animation: animate 100s -100s linear infinite;
        will-change: transform;
        
        &:nth-child(2) {
            animation: animate2 100s -50s linear infinite;
        }
    }
}

@keyframes animate {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes animate2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-200%);
    }
}